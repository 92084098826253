import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tab, Tabs } from '@mui/material';
import { useQuery } from 'react-query';

import useTopPanel from 'hooks/useTopPanel';
import useSetQuery from 'hooks/useSetQuery';
import useSearch from 'hooks/useSearch';
import PaymentBankTable from 'components/ui/tables/PaymentBankTable';
import BankFiltersComponent from 'components/ui/filters/BankFiltersComponent';
import SearchInput from 'components/SearchInput';
import PaymentBankViewModal from './PaymentBankViewModal';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import SetContractModal from './SetContractModal';

export default memo(function PaymentBank() {
  const { setComponent } = useTopPanel();
  const axiosPrivate = useAxiosPrivate();
  const query = useSearch();
  const setQuery = useSetQuery();
  const { t, i18n } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['paymentBanks', query, refetch],
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/bank/counts');
      return response.data.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    setComponent(<div className='component-title'>{t('bank.title')}</div>);
  }, [i18n.language]);

  const onChange = (i, val) => {
    setQuery('type', val);
    setQuery('page');
  };

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center justify-between my-shadow-2 rounded-lg px-4 w-full h-14'>
            <div className='flex gap-1 items-center'>
              <Button
                variant='filterOutlined'
                color='primary'
                startIcon={<i className='bi bi-filter' />}
                className='!mr-2'
                onClick={() => setExpanded((prev) => !prev)}
              >
                {t('common.button.filter')}
              </Button>
              <Tabs
                value={query.get('type') || ''}
                sx={{ minHeight: 32, height: 32 }}
                onChange={onChange}
              >
                <Tab
                  label={
                    <span className='flex items-center justify-between'>
                      Barchasi&nbsp;
                      <span className='rounded-xl bg-blue-200 py-0.5 px-1 text-sm'>
                        {refetch || isLoading || isFetching ? (
                          <i className={`bi bi-arrow-repeat animate-spin`} />
                        ) : (
                          data?.all
                        )}
                      </span>
                    </span>
                  }
                  value=''
                  sx={{ minHeight: 30, height: 30 }}
                />
                <Tab
                  label={
                    <span className='flex items-center justify-between'>
                      Qabul qilinmagan&nbsp;
                      <span className='rounded-xl bg-blue-200 py-0.5 px-1 text-sm'>
                        {refetch || isLoading || isFetching ? (
                          <i className={`bi bi-arrow-repeat animate-spin`} />
                        ) : (
                          data?.unaccepted
                        )}
                      </span>
                    </span>
                  }
                  value='unaccept'
                  sx={{ minHeight: 30, height: 30 }}
                />
                <Tab
                  label={
                    <span className='flex items-center justify-between'>
                      Qabul qilingan&nbsp;
                      <span className='rounded-xl bg-blue-200 py-0.5 px-1 text-sm'>
                        {refetch || isLoading || isFetching ? (
                          <i className={`bi bi-arrow-repeat animate-spin`} />
                        ) : (
                          data?.accepted
                        )}
                      </span>
                    </span>
                  }
                  value='accepted'
                  sx={{ minHeight: 30, height: 30 }}
                />
              </Tabs>
            </div>
            <SearchInput inputKey='name' />
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4'>
            <Button
              variant='action'
              className='!mr-1'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='action'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
        {expanded && (
          <div className='my-shadow-2 rounded-lg px-4 w-full mt-2'>
            <BankFiltersComponent />
          </div>
        )}
        <div
          className={`component-table-wrapper ${
            expanded ? 'h-[calc(100vh-231px)]' : 'h-[calc(100vh-155px)]'
          }`}
        >
          <PaymentBankTable
            emitRefetch={{ refetch, setRefetch }}
            dataPath={'admin/bank/index'}
            emitTableColumns={{ open, setOpen }}
            tableName='payments/bank'
            actionModal={{
              setOpen: setViewModalOpen,
              setData: setSelected,
              icon: 'bi bi-arrows-angle-expand',
            }}
            setOpenContractModal={setOpenContractModal}
            actionAccept={{
              link: 'admin/bank/pay',
              successMsg: 'Success',
            }}
            actionReject={{
              link: 'admin/bank/132132',
              successMsg: 'Success',
            }}
            actionDelete={{
              link: 'admin/bank/remove',
              key: 'id',
            }}
          />
        </div>
        <PaymentBankViewModal
          open={viewModalOpen}
          setOpen={setViewModalOpen}
          setOpenContractModal={setOpenContractModal}
          refetch={() => setRefetch(true)}
          data={selected}
          refetching={refetch}
        />
        <SetContractModal
          open={openContractModal}
          setOpen={setOpenContractModal}
          refetch={() => setRefetch(true)}
          data={selected}
        />
      </div>
    </div>
  );
});
