import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

export default function CustomContractCellBankTable({
  data,
  setData,
  setOpen,
}) {
  const onClick = () => {
    setData(data);
    setOpen(true);
  };
  if (!data?.contract?.id) {
    return (
      <Button variant='outlined' className='!h-16 !w-36' onClick={onClick}>
        <i className='bi bi-plus-circle' />
      </Button>
    );
  }

  const { custom, name, id, date } = data?.contract;

  return (
    <div className='flex'>
      <div>
        <h1 className='text-[13px]'>
          {custom?.surname} {custom?.name} {custom?.middlename}
        </h1>
        <Link
          to={`/admin/contract/view/${id}`}
          className='my-0.5 text-[12px] no-underline text-blue-500 hover:underline hover:text-blue-700'
        >
          {name}
        </Link>
        <p className='text-[12px] text-gray-400'>
          {t('common.fields.date')}: {date}
        </p>
      </div>
      <div>
        <Button
          variant='outlined'
          size='small'
          sx={{ minWidth: 30 }}
          onClick={onClick}
        >
          <i className='bi bi-arrow-repeat' />
        </Button>
      </div>
    </div>
  );
}
