import { Button } from '@mui/material';
import React from 'react';
import { useMutation } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';

export default function OperatorActiveLead() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const defineLead = useMutation({
    mutationFn: async function () {
      const response = await axiosPrivate.get('/crm/real-time/check');
      return response.data.data;
    },
  });
  const onClick = () => {
    defineLead.mutateAsync('', {
      onSuccess: (data) => {
        if (data?.crm_lead_id) {
          navigate(`/admin/crm/lead/edit/${data?.crm_lead_id}`);
        }
      },
      onError: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  };

  return (
    <Button
      fullWidth
      size='small'
      color='warning'
      variant='contained'
      startIcon={<i className='bi bi-headset' />}
      onClick={onClick}
    >
      Aktiv lid
    </Button>
  );
}
