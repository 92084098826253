import { Grid } from '@mui/material';
import React from 'react';
import AccountNumberFilter from './items/AccountNumberFilter';
import DateRangeFilter from './items/DateRangeFilter';

const BankFiltersComponent = () => {
  return (
    <div className='mb-1 pb-2'>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, lg: 2 }}
        rowSpacing={1}
        columns={{ xs: 12, sm: 12, lg: 12 }}
      >
        <Grid item={true} lg={3} sm={6} xs={12}>
          <DateRangeFilter />
        </Grid>
        <Grid item={true} lg={3} sm={6} xs={12}>
          <AccountNumberFilter />
        </Grid>
      </Grid>
    </div>
  );
};

export default BankFiltersComponent;
