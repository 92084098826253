import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

export default function CustomSumCellBankTable({ data }) {
  const [valuta, setValuta] = useState(data?.isvalute);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sumUzs = data?.summa;
  const sumUsd = data?.invalute;
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();

  const submit = async (value) => {
    try {
      const response = await axiosPrivate.post(
        `/admin/bank/changecourse/${data?.id}`,
        JSON.stringify({ isvalute: value }),
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response?.data && response?.data?.status) {
        setValuta(value);
        data.isvalute = value;
        sendNotification({
          msg: response?.data?.message,
          variant: 'success',
        });
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
    setIsSubmitting(false);
  };

  const onChange = (event) => {
    setIsSubmitting(true);
    const { value } = event.target;
    submit(value);
  };

  return (
    <RadioGroup value={valuta} onChange={onChange}>
      <FormControlLabel
        disabled={data?.iscount == '1'}
        value={'0'}
        control={
          isSubmitting && valuta != '0' ? (
            <span className='p-[9px] py-[8.3px] mr-[3px]'>
              <CircularProgress size='16.67px' />
            </span>
          ) : (
            <Radio size='small' />
          )
        }
        label={
          <span className='whitespace-nowrap'>
            <NumericFormat
              value={sumUzs}
              allowNegative={false}
              displayType={'text'}
              thousandSeparator={' '}
              decimalScale={2}
              className='w-full text-right text-sm'
              suffix=' UZS'
            />
          </span>
        }
      />
      <FormControlLabel
        disabled={data?.iscount == '1'}
        value={'1'}
        control={
          isSubmitting && valuta != '1' ? (
            <span className='p-[9px] py-[8.3px] mr-[3px]'>
              <CircularProgress size='16.67px' />
            </span>
          ) : (
            <Radio size='small' />
          )
        }
        label={
          <span className='whitespace-nowrap'>
            <NumericFormat
              value={sumUsd}
              allowNegative={false}
              displayType={'text'}
              thousandSeparator={' '}
              decimalScale={2}
              className='w-full text-right text-sm'
              suffix=' USD'
            />
          </span>
        }
      />
    </RadioGroup>
  );
}
