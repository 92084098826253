import { Fragment } from 'react';
import Shaxmatka2Row from './Shaxmatka2Row';
import HOME_TYPE from 'shared/homeTypeList';
import { useTranslation } from 'react-i18next';

const Shaxmatka2BlockItem = ({
  blockItem,
  blockIndex,
  toggleSelectionItem = () => {},
  settingsMenuValue = '',
}) => {
  const { t } = useTranslation();
  const getRoomsByStatus = (statusCode) => {
    return blockItem?.homes.length > 0
      ? blockItem?.homes.filter((item) => item.status == statusCode).length
      : 0;
  };

  const getFullFloorsNumberByBlock = (block) => {
    let a = Array.from(new Set(block.homes.map((item) => item.stage)));
    if (a.includes('-1')) {
      a.push('0');
    }
    a.sort((x, y) => x - y);
    return a.reverse();
  };

  return (
    <Fragment>
      <div className='my-2 ml-9 leading-4'>
        <span className='font-medium text-base text-line-1'>
          {blockItem?.name}
        </span>
        <div className='text-[12px] leading-3 flex items-center'>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-base-color-disabled'></div>
            {getRoomsByStatus(HOME_TYPE.ACTIVE.code)}
          </div>
          <span className='mx-1 text-gray-500'>|</span>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-orange-400'></div>
            {getRoomsByStatus(HOME_TYPE.TIME.code)}
          </div>
          <span className='mx-1 text-gray-500'>|</span>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-red-500'></div>
            {getRoomsByStatus(HOME_TYPE.ORDERED.code)}
          </div>
          <span className='mx-1 text-gray-500'>|</span>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-gray-400'></div>
            {getRoomsByStatus(HOME_TYPE.DISABLED.code)}
          </div>
        </div>
      </div>
      {getFullFloorsNumberByBlock(blockItem).map((floorNumber, index) => (
        <div
          className={`sheet-row floor-${floorNumber}`}
          key={`block-${blockItem?.id}-row-${index}`}
          id={`block-${blockItem?.id}-row-${index}`}
        >
          <Shaxmatka2Row
            homesData={blockItem?.homes}
            blockIndex={blockIndex}
            floorNumber={floorNumber}
            size={new Set(blockItem?.homes.map((item) => item.stage)).size}
            toggleSelectionItem={toggleSelectionItem}
            settingsMenuValue={settingsMenuValue}
          />
        </div>
      ))}
    </Fragment>
  );
};

export default Shaxmatka2BlockItem;
