import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import FormCurrencyGenericField from 'components/ui/form/FormCurrencyGenericField';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { useQuery } from 'react-query';

const validationSchema = yup.object({
  isvalute: yup.number().required('payment.addModal.validation.typeId'),
  valute: yup.number().required('payment.addModal.validation.typeId'),
  invalute: yup.number().required('payment.addModal.validation.sum'),
});

const BankViewModal = (props) => {
  const {
    open,
    setOpen,
    refetch,
    refetching,
    data: contractData,
    setOpenContractModal,
  } = props;
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, isLoading, isfetching } = useQuery({
    queryKey: [
      '/admin/bank/edit',
      contractData?.id,
      contractData?.contract?.id,
      refetching,
    ],
    queryFn: async () => {
      try {
        const response = await axiosPrivate.get(
          `/admin/bank/edit/${contractData?.id}`
        );
        return response?.data?.data;
      } catch (error) {
        throw error;
      }
    },
    enabled: Boolean(contractData?.id),
  });

  const formik = useFormik({
    initialValues: {
      comment: '',
      invalute: contractData?.invalute || '0',
      valute: contractData?.valute || '0',
      isvalute: contractData?.isvalute || '0',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const response = await axiosPrivate.post(
          `/admin/bank/save/${contractData?.id}`,
          JSON.stringify(values),
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (response.data && response.data.status) {
          sendNotification({
            msg: t('payment.addModal.alerts.success', {
              name: contractData?.name,
            }),
            variant: 'success',
          });
          setIsSubmitting(false);
          handleClose();
          refetch();
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      invalute: contractData?.invalute || '0',
      valute: contractData?.valute || '0',
      isvalute: contractData?.isvalute || '0',
    });
    setIsSubmitting(false);
  }, [contractData, open]);

  const onValuteChange = (value) => {
    if (!Number(value)) {
      formik.setFieldValue('invalute', '', false);
      return;
    }
    if (contractData?.summa) {
      const invalute = contractData.summa / value;
      formik.setFieldValue(
        'invalute',
        Number(parseFloat(invalute).toFixed(2)),
        false
      );
    }
  };

  const onInValuteChange = (value) => {
    if (!Number(value)) {
      formik.setFieldValue('valute', '', false);
      return;
    }
    if (contractData?.summa) {
      const valute = contractData.summa / value;
      formik.setFieldValue(
        'valute',
        Number(parseFloat(valute).toFixed(2)),
        false
      );
    }
  };

  const onRadioChange = (e) => {
    const { name, value } = e?.target;
    formik.setFieldValue(name, value, false);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        <span className='pr-5'>{t('bank.view.title')}</span>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 2 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12 }}
            maxWidth={760}
          >
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.bankPaymentNumber')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.ext_id}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.recipientStir')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.inn_cr}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.payerStir')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.inn_dt}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.recipientMfo')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.mfo_cr}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.payerBankMfo')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.mfo_dt}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.recipientBankAccount')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.account_cr}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.payerBankAccount')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.account_dt}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.recipientName')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.name_cr}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.payerName')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.name_dt}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.acceptanceCurrency')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  <RadioGroup
                    row
                    name='isvalute'
                    value={formik?.values?.isvalute}
                    onChange={onRadioChange}
                  >
                    <FormControlLabel
                      value='0'
                      control={<Radio size='small' />}
                      label='UZS'
                    />
                    <FormControlLabel
                      value='1'
                      control={<Radio size='small' />}
                      label='USD'
                    />
                  </RadioGroup>
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={6} xs={12} />
            <Grid item={true} sm={6} xs={12}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2 }}
                rowSpacing={1}
                columns={{ xs: 12, sm: 12 }}
              >
                <Grid item={true} sm={6} xs={12}>
                  <p className='text-gray-500 text-sm'>
                    {t('common.fields.paymentAmount')}
                  </p>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <span className='text-gray-800 text-base font-medium'>
                    <NumericFormat
                      value={contractData?.summa}
                      allowNegative={false}
                      displayType={'text'}
                      thousandSeparator={' '}
                      decimalScale={2}
                      className='w-full text-right text-sm'
                      suffix=' UZS'
                    />
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} sm={6} xs={12} />
            <Grid item={true} sm={6} xs={12}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2 }}
                rowSpacing={1}
                columns={{ xs: 12, sm: 12 }}
              >
                <Grid item={true} sm={6} xs={12}>
                  <p className='text-gray-500 text-sm h-full flex items-center'>
                    1 USD
                  </p>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <FormCurrencyGenericField
                    delay={0.2}
                    suffix='UZS'
                    fieldName='valute'
                    formik={formik}
                    onChange={onValuteChange}
                    decimalScale={2}
                    size='small'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} sm={6} xs={12} />
            <Grid item={true} sm={6} xs={12}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2 }}
                rowSpacing={1}
                columns={{ xs: 12, sm: 12 }}
              >
                <Grid item={true} sm={6} xs={12}>
                  <p className='text-gray-500 text-sm h-full flex items-center'>
                    {t('common.fields.currency')}:
                  </p>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <FormCurrencyGenericField
                    delay={0.2}
                    suffix='USD'
                    fieldName='invalute'
                    formik={formik}
                    onChange={onInValuteChange}
                    decimalScale={2}
                    size='small'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <div>
                <p className='text-gray-500 text-sm'>
                  {t('bank.view.comment')}
                </p>
                <span className='text-gray-800 text-base font-medium'>
                  {contractData?.nazpla}
                </span>
              </div>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {isLoading || isfetching ? (
                <div className='h-full flex items-center justify-center'>
                  <CircularProgress size={'30px'} />
                </div>
              ) : (
                <div>
                  <p className='text-gray-500 text-sm'>
                    {t('bank.view.infos')}{' '}
                    {data?.contract?.id ? (
                      <Button
                        size='small'
                        sx={{ minWidth: 30 }}
                        onClick={() => setOpenContractModal(true)}
                      >
                        <i className='bi bi-arrow-repeat !text-base' />
                      </Button>
                    ) : null}
                  </p>
                  {data?.contract?.id ? (
                    <div>
                      <div className='mb-2'>
                        <p className='text-gray-600 text-sm'>
                          {t('common.fields.contractDate')}
                        </p>
                        <p className='text-[12px] font-medium text-black'>
                          {data?.contract?.date}
                        </p>
                      </div>
                      <div className='mb-2'>
                        <p className='text-gray-600 text-sm'>
                          {t('common.fields.contractNumber')}
                        </p>
                        <p className='text-[12px] font-medium text-black'>
                          {data?.contract?.name}
                        </p>
                      </div>
                      <div>
                        <p className='text-gray-600 text-sm'>
                          {t('common.table.custom')}
                        </p>
                        <p className='text-[12px] font-medium text-black'>
                          {data?.contract?.custom?.surname}&nbsp;
                          {data?.contract?.custom?.name}&nbsp;
                          {data?.contract?.custom?.middlename}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <Button
                      variant='outlined'
                      className='!h-16 !w-36 !mt-1'
                      onClick={() => setOpenContractModal(true)}
                    >
                      <i className='bi bi-plus-circle' />
                    </Button>
                  )}
                </div>
              )}
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                rows={6}
                multiline
                label={t('bank.view.comment')}
                fieldName='comment'
                formik={formik}
                decimalScale={1}
              />
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                delay={0.5}
                isSubmitting={isSubmitting}
                formType='dialog'
                cancelColor='inherit'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(BankViewModal);
