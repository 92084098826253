import { Button, ButtonBase, CircularProgress, Grid } from '@mui/material';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { fadeUp, tabItem } from 'utils/motion';
import CLIENT_TYPE from 'shared/clientTypeList';
import TabPanel from 'components/ui/tabs/TabPanel';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSetQuery from 'hooks/useSetQuery';
import useNotification from 'hooks/useNotification';
import { fromTextToBase64parser } from 'utils/textParser';
import FormDateField from 'components/ui/form/FormDateField';
import FormTextField from 'components/ui/form/FormTextField';
import FormPhoneField from 'components/ui/form/FormPhoneField';
import FormNumberField from 'components/ui/form/FormNumberField';
import FormSelectField from 'components/ui/form/FormSelectField';
import PhoneFormat from 'components/ui/text-formats/PhoneFormat';
import FormPatternField from 'components/ui/form/FormPatternField';
import { clientTypeSelectOptions } from 'shared/selectOptionsList';
import FormPassportField from 'components/ui/form/FormPassportField';
import FormNumberTextField from 'components/ui/form/FormNumberTextField';
import FormMultiplePhonesField from 'components/ui/form/FormMultiplePhonesField';
import SimpleSelectField from 'components/ui/simple-fields/select/SimpleSelectField';

const physicalValidationSchema = yup.object({
  name: yup.string().required('change.tab.client.validation.physical.name'),
  middlename: yup
    .string()
    .required('change.tab.client.validation.physical.middleName'),
  surname: yup
    .string()
    .required('change.tab.client.validation.physical.surname'),
  phones: yup
    .array()
    .of(
      yup.object({
        id: yup.string().optional(),
        phone: yup
          .string()
          .length(17, 'client.addEdit.validation.physical.phoneValid')
          .required('client.addEdit.validation.physical.phone'),
      })
    )
    .min(1)
    .required('client.addEdit.validation.physical.phone'),
  passport_series: yup
    .string()
    .trim()
    .min(10, 'change.tab.client.validation.physical.passportSeriesFormat')
    .max(10, 'change.tab.client.validation.physical.passportSeriesFormat')
    .required('change.tab.client.validation.physical.passportSeries'),
  issue: yup
    .date()
    .nullable()
    .typeError('change.tab.client.validation.physical.issueValid')
    .required('change.tab.client.validation.physical.issue'),
  authority: yup
    .string()
    .required('change.tab.client.validation.physical.authority'),
  birthday: yup
    .date()
    .nullable()
    .typeError('change.tab.client.validation.physical.birthdayValid')
    .required('change.tab.client.validation.physical.birthday'),
  region_id: yup
    .string()
    .required('change.tab.client.validation.physical.region'),
  city: yup.string().required('change.tab.client.validation.physical.city'),
  home: yup.string().required('change.tab.client.validation.physical.address'),
  inn: yup
    .string()
    .trim()
    .min(9, 'change.tab.client.validation.physical.tinFormat')
    .max(9, 'change.tab.client.validation.physical.tinFormat')
    .optional(),
  pnfl: yup
    .string()
    .trim()
    .min(14, 'change.tab.client.validation.physical.pnflFormat')
    .max(14, 'change.tab.client.validation.physical.pnflFormat')
    .required('change.tab.client.validation.physical.pnfl'),
  work_place: yup.string().optional(),
});

const legalValidationSchema = yup.object({
  name: yup.string().required('change.tab.client.validation.legal.name'),
  phones: yup
    .array()
    .of(
      yup.object({
        id: yup.string().optional(),
        phone: yup
          .string()
          .length(17, 'client.addEdit.validation.physical.phoneValid')
          .required('client.addEdit.validation.physical.phone'),
      })
    )
    .min(1)
    .required('client.addEdit.validation.physical.phone'),
  region_id: yup.string().required('change.tab.client.validation.legal.region'),
  city: yup.string().required('change.tab.client.validation.legal.city'),
  home: yup.string().required('change.tab.client.validation.legal.address'),
  inn: yup
    .string()
    .trim()
    .min(9, 'change.tab.client.validation.legal.tinFormat')
    .max(9, 'change.tab.client.validation.legal.tinFormat')
    .required('change.tab.client.validation.legal.tin'),
  mfo: yup.number().required('change.tab.client.validation.legal.mfo'),
  oked: yup.number().required('change.tab.client.validation.legal.oked'),
  account_number: yup
    .string()
    .min(20, {
      label: 'change.tab.client.validation.legal.accountNumberMin',
      value: 20,
    })
    .max(20, {
      label: 'change.tab.client.validation.legal.accountNumberMax',
      value: 20,
    })
    .required('change.tab.client.validation.legal.accountNumber'),
  bank_name: yup.string().required('change.tab.client.validation.legal.bank'),
});

const TabOne = ({
  clientName,
  clientSearch,
  setClientSearch,
  appear,
  clientId,
  setClientId,
  setSelectedContract,
}) => {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(false);
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const setQuery = useSetQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isClientSearching, setIsClientSearching] = useState(false);
  const [clientType, setClientType] = useState(CLIENT_TYPE.PHYSICAL.code);

  useEffect(() => {
    if (clientSearch.value) {
      searchClients(clientSearch.isAutoRequest);
    }
  }, [clientSearch]);

  const physicalInitialValues = {
    name: '',
    middlename: '',
    surname: '',
    phones: [
      {
        phone: '',
      },
    ],
    passport_series: '',
    issue: null,
    inn: '',
    pnfl: '',
    authority: '',
    birthday: null,
    region_id: '',
    city: '',
    home: '',
    work_place: '',
  };

  const legalInitialValues = {
    name: '',
    phones: [
      {
        phone: '',
      },
    ],
    region_id: '',
    city: '',
    home: '',
    inn: '',
    mfo: '',
    oked: '',
    account_number: '',
    bank_name: '',
  };

  const formikPhysical = useFormik({
    initialValues: physicalInitialValues,
    validationSchema: physicalValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        if (clientId) {
          const response = await axiosPrivate.post(
            `/admin/custom/customupdate/${clientId}`,
            parseToFormData(values),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
          handleResponse(response);
        } else {
          const response = await axiosPrivate.post(
            '/admin/custom/customstore',
            JSON.stringify(values),
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (response.data && response.data.status && response.data.data) {
            sendNotification({
              msg: t('order.modal.add.alerts.clientSuccess'),
              variant: 'success',
            });
            setClientsList([{ ...response.data.data, isSelected: true }]);
            setClientId(response.data.data.id);
          }
          setIsSubmitting(false);
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
        setIsSubmitting(false);
      }
    },
  });

  const formikLegal = useFormik({
    initialValues: legalInitialValues,
    validationSchema: legalValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        if (clientId) {
          const response = await axiosPrivate.post(
            `/admin/custom/legalupdate/${clientId}`,
            parseToFormData(values),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
          handleResponse(response);
        } else {
          const response = await axiosPrivate.post(
            '/admin/custom/legalstore',
            JSON.stringify(values),
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (response.data && response.data.status && response.data.data) {
            sendNotification({
              msg: t('order.modal.add.alerts.clientSuccess'),
              variant: 'success',
            });
            // setClientsList(response.data.data);
            setClientId('');
          }
          setIsSubmitting(false);
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
        setIsSubmitting(false);
      }
    },
  });

  const parseToFormData = (values) => {
    let formData = new FormData();
    for (let key in values) {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    }
    return formData;
  };

  const handleResponse = (response) => {
    if (response.data && response.data.status) {
      sendNotification({
        msg: t('change.tab.client.alerts.success'),
        variant: 'success',
      });
    }
    if (clientSearch.value) {
      searchClients(clientSearch.isAutoRequest);
    }
    setIsSubmitting(false);
  };

  const handleClientType = (value) => {
    formikPhysical.resetForm();
    formikLegal.resetForm();
    setClientId('');
    setClientIdInQuery('');
    setSelectedClientId('');
    setClientType(value);
  };

  const setClientIdInQuery = (cId) => {
    if (cId) {
      searchParams.set('clientId', cId);
    } else {
      searchParams.delete('clientId');
    }
    setSearchParams(searchParams);
  };

  const searchClients = async (isAutoRequest = false, savePhones, phones) => {
    formikPhysical.resetForm();
    formikLegal.resetForm();
    if (savePhones) {
      formikPhysical.setFieldValue('phones', phones);
      formikLegal.setFieldValue('phones', phones);
    }
    if (!isAutoRequest) {
      setClientId('');
    }
    setSelectedClientId('');
    setClientsLoading(true);
    let response = await axiosPrivate.get(
      `/dictionary/customs?name=${
        isAutoRequest ? searchParams.get('clientName') : clientName
      }`
    );
    if (response.data && response.data.status) {
      setClientsList(
        response.data.data.data.map((item) => ({
          ...item,
          // isSelected: parseInt(searchParams.get('clientId')) == item?.id,
        }))
      );
      setClientSearch({ value: false, isAutoRequest: false });
    }
    setClientsLoading(false);
  };

  const handleClient = (clientId) => {
    clientsList.forEach((item) => {
      if (item.id == clientId) {
        item.isSelected = !item.isSelected;
      } else {
        item.isSelected = false;
      }
    });

    if (selectedClientId == clientId) {
      setClientId('');
      setClientIdInQuery('');
      setSelectedClientId('');
    } else {
      setClientId(clientId);
      setClientIdInQuery(clientId);
      setSelectedClientId(clientId);
      setClientValuesToForm(clientId);
    }
    setSelectedContract('');
  };

  const setClientValuesToForm = (clientId) => {
    const client = clientsList.filter((item) => item.id == clientId)[0];
    if (client?.client_type == CLIENT_TYPE.PHYSICAL.code) {
      setClientType(CLIENT_TYPE.PHYSICAL.code);
      const newValues = {
        name: client.name,
        middlename: client.middlename,
        surname: client.surname,
        phones: client.phones || formikPhysical.values.phones || [],
        // phones: formikPhysical.values.phones || [],
        passport_series: client?.detail?.passport_series || '',
        issue: client?.detail?.issue || null,
        inn: client?.detail?.inn || '',
        pnfl: client?.detail?.pnfl || '',
        authority: client?.detail?.authority || '',
        birthday: client?.detail?.birthday || null,
        region_id: client?.detail?.region_id || '',
        city: client?.detail?.city || '',
        home: client?.detail?.home || '',
        work_place: client?.detail?.work_place || '',
      };
      formikPhysical.setValues(newValues);
    } else {
      setClientType(CLIENT_TYPE.LEGAL.code);
      const newValues = {
        name: client?.name,
        phones: client.phones || formikLegal.values.phones || [],
        region_id: client?.detail?.region_id || '',
        city: client?.detail?.city || '',
        home: client?.detail?.home || '',
        inn: client?.detail?.inn || '',
        mfo: client?.detail?.mfo || '',
        oked: client?.detail?.oked || '',
        account_number: client?.detail?.account_number || '',
        bank_name: client?.detail?.bank_name || '',
      };
      formikLegal.setValues(newValues);
    }
  };

  const handleSearchClient = () => {
    if (
      formikPhysical.values.passport_series &&
      formikPhysical.values.birthday &&
      !(
        formikPhysical.touched.passport_series &&
        Boolean(formikPhysical.errors.passport_series) &&
        formikPhysical.touched.birthday &&
        Boolean(formikPhysical.errors.birthday)
      )
    ) {
      setIsClientSearching(true);
      axios
        .get(
          `https://backsmartsales.uz/api/takedata?passport_series=${formikPhysical.values.passport_series}&birthday=${formikPhysical.values.birthday}`
        )
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            const dataJSON = response.data[0];
            if (
              dataJSON &&
              dataJSON?.data?.info &&
              dataJSON?.data?.info &&
              dataJSON?.data?.info?.data
            ) {
              const newValues = {
                name: dataJSON?.data?.info?.data?.name || '',
                middlename: dataJSON?.data?.info?.data?.patronymic_name || '',
                surname: dataJSON?.data?.info?.data?.sur_name || '',
                phones: formikPhysical.values.phones || [],
                passport_series: formikPhysical.values.passport_series || '',
                issue: dataJSON?.data?.info?.data?.given_date || null,
                authority: dataJSON?.data?.info?.data?.given_place || '',
                birthday: formikPhysical.values.birthday || '',
                region_id: formikPhysical.values.region_id || '',
                city: formikPhysical.values.city || '',
                home: dataJSON?.data?.info?.data?.living_place
                  ? dataJSON?.data?.info?.data?.living_place
                  : dataJSON?.data?.info?.data?.address,
                work_place: formikPhysical.values.work_place || '',
                inn: dataJSON?.data?.info?.data?.tin || '',
                pnfl: dataJSON?.data?.info?.data?.pinfl || '',
              };
              setSelectedClientId('');
              formikPhysical.setValues(newValues);
            }
          }
        })
        .catch((err) => {})
        .finally(() => setIsClientSearching(false));
    } else {
    }
  };
  const handleKeyDown = async (event) => {
    if (event.keyCode == 13) {
      setQuery('clientName', event?.target?.value);
      const phones =
        clientType == CLIENT_TYPE.PHYSICAL.code
          ? formikPhysical.values?.phones
          : formikLegal?.values?.phones;
      searchClients(clientSearch.isAutoRequest, true, phones);
    }
  };

  const handleAddContract = (client) => {
    let path = `/admin/contract/add-from-change?clientData=${fromTextToBase64parser(
      JSON.stringify(client)
    )}`;
    navigate(path, { state: { allowed: true } });
  };

  return (
    <motion.div
      variants={tabItem({
        duration: 0,
      })}
      initial='hidden'
      animate={appear ? 'show' : 'hidden'}
    >
      <TabPanel value={0} index={0} className='-mx-6'>
        <div className='flex justify-between'>
          <form
            onSubmit={() => {
              return false;
            }}
            className='w-2/3'
          >
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} lg={4} sm={6} xs={12}>
                <SimpleSelectField
                  delay={0}
                  label={t('common.fields.clientType')}
                  name='name'
                  value={clientType}
                  changeFn={handleClientType}
                  options={clientTypeSelectOptions}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              {clientType == CLIENT_TYPE.PHYSICAL.code ? (
                <Fragment>
                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPassportField
                      delay={0}
                      label={t('common.fields.passportSeries')}
                      fieldName='passport_series'
                      formik={formikPhysical}
                    />
                  </Grid>
                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormDateField
                      delay={0}
                      label={t('common.fields.birthday')}
                      fieldName='birthday'
                      formik={formikPhysical}
                    />
                  </Grid>
                  <Grid item={true} lg={4} sm={6} xs={12}>
                    {clientId ? null : (
                      <Button
                        variant='outlined'
                        color='info'
                        onClick={() => handleSearchClient()}
                        disabled={isClientSearching}
                        className='!mt-2 !h-10'
                        fullWidth
                      >
                        {isClientSearching ? (
                          <div className='flex items-center justify-center'>
                            <CircularProgress size={17.5} color='inherit' />
                          </div>
                        ) : (
                          <div className='flex items-center text-base'>
                            <i className='bi bi-search mr-1 text-lg' />
                            {t('client.addEdit.search')}
                          </div>
                        )}
                      </Button>
                    )}
                  </Grid>
                </Fragment>
              ) : null}
              {/* <Grid item={true} lg={4} sm={6} xs={12}>
                <SimpleSelectField
                  delay={0}
                  label={t('common.fields.clientType')}
                  name='name'
                  value={clientType}
                  changeFn={handleClientType}
                  options={clientTypeSelectOptions}
                />
              </Grid> */}

              {clientType == CLIENT_TYPE.PHYSICAL.code ? (
                <Fragment>
                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.name')}
                      fieldName='name'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.surname')}
                      fieldName='surname'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.middleName')}
                      fieldName='middlename'
                      formik={formikPhysical}
                    />
                  </Grid>

                  {/* <Grid item={true} lg={4} sm={6} xs={12}>
										<FormMultiplePhonesField
											delay={0}
											label={t("common.fields.phone")}
											fieldName="phones"
											childFieldName="phone"
											formik={formikPhysical}
										/>
									</Grid> */}

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormMultiplePhonesField
                      delay={0.6}
                      label={t('common.fields.phone')}
                      fieldName='phones'
                      childFieldName='phone'
                      formik={formikPhysical}
                      onKeyDown={handleKeyDown}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPatternField
                      delay={0}
                      label={t('common.fields.tin')}
                      fieldName='inn'
                      formik={formikPhysical}
                      format='#########'
                    />
                  </Grid>

                  {/* <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPassportField
                      delay={0}
                      label={t('common.fields.passportSeries')}
                      fieldName='passport_series'
                      formik={formikPhysical}
                    />
                  </Grid> */}

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPatternField
                      delay={0}
                      label={t('common.fields.pnfl')}
                      fieldName='pnfl'
                      formik={formikPhysical}
                      format='##############'
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormDateField
                      delay={0}
                      label={t('common.fields.issue')}
                      fieldName='issue'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.authority')}
                      fieldName='authority'
                      formik={formikPhysical}
                    />
                  </Grid>

                  {/* <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormDateField
                      delay={0}
                      label={t('common.fields.birthday')}
                      fieldName='birthday'
                      formik={formikPhysical}
                    />
                  </Grid> */}

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormSelectField
                      delay={0}
                      fieldName='region_id'
                      label={t('common.fields.region')}
                      formik={formikPhysical}
                      path={'/dictionary/regions'}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.city')}
                      fieldName='city'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.home')}
                      fieldName='home'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.workPlace')}
                      fieldName='work_place'
                      formik={formikPhysical}
                    />
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.companyName')}
                      fieldName='name'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPhoneField
                      delay={0}
                      label={t('common.fields.phone')}
                      fieldName='phone'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPhoneField
                      delay={0}
                      label={t('common.fields.phone2')}
                      fieldName='phone2'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.bank')}
                      fieldName='bank_name'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberField
                      delay={0}
                      label={t('common.fields.tin')}
                      fieldName='inn'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberField
                      delay={0}
                      label={t('common.fields.mfo')}
                      fieldName='mfo'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberField
                      delay={0}
                      label={t('common.fields.oked')}
                      fieldName='oked'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberTextField
                      delay={0}
                      label={t('common.fields.accountNumber')}
                      fieldName='account_number'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormSelectField
                      delay={0}
                      fieldName='region_id'
                      label={t('common.fields.region')}
                      formik={formikLegal}
                      path={'/dictionary/regions'}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.city')}
                      fieldName='city'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0}
                      label={t('common.fields.companyAddress')}
                      fieldName='home'
                      formik={formikLegal}
                    />
                  </Grid>
                </Fragment>
              )}

              <Grid item={true} sm={12} xs={12}>
                <div className='text-center'>
                  <Button
                    color='success'
                    variant='contained'
                    type='button'
                    component={motion.button}
                    variants={fadeUp(30, 'tween', 0, 0.5)}
                    initial='hidden'
                    animate='show'
                    viewport={{ once: true, amount: 0.25 }}
                    disabled={isSubmitting}
                    onClick={
                      clientType == CLIENT_TYPE.PHYSICAL.code
                        ? formikPhysical.handleSubmit
                        : formikLegal.handleSubmit
                    }
                  >
                    {isSubmitting && (
                      <CircularProgress
                        size={15}
                        color='inherit'
                        className='mr-1'
                      />
                    )}
                    {clientId
                      ? t('change.tab.client.action.update')
                      : t('order.modal.add.addClient')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>

          <div className='clients-database-wrapper w-1/3 pl-8 py-2'>
            <div className='clients-database-title text-xl text-base-color'>
              {t('change.tab.client.clientsData')}:
            </div>
            <div className='clients-database-add-edit'></div>
            <div className='clients-database-body flex flex-col mt-2'>
              {clientsLoading ? (
                <div className='circular-progress-box py-5'>
                  <CircularProgress size={30} />
                </div>
              ) : clientsList && clientsList.length > 0 ? (
                <Fragment>
                  {clientsList.map((client) =>
                    client?.isSelected ? (
                      <Fragment key={client.id}>
                        <ButtonBase
                          className='client-item item-selected'
                          id={`client-${client.id}`}
                          onClick={() => handleClient(client.id)}
                        >
                          <div className='name'>
                            {client.surname} {client.name} {client.middlename}
                          </div>
                          <div className='phone'>
                            <PhoneFormat value={client.phone} />
                          </div>
                        </ButtonBase>
                        <Button
                          color='success'
                          variant='contained'
                          type='button'
                          fullWidth
                          onClick={() => handleAddContract(client)}
                        >
                          {t('order.view.createContract')}
                        </Button>
                      </Fragment>
                    ) : (
                      <ButtonBase
                        className={`client-item${
                          client?.isSelected ? ' item-selected' : ''
                        }`}
                        id={`client-${client.id}`}
                        key={client.id}
                        onClick={() => handleClient(client.id)}
                      >
                        <div className='name'>
                          {client.surname} {client.name} {client.middlename}
                        </div>
                        <div className='phone'>
                          <PhoneFormat value={client.phone} />
                        </div>
                      </ButtonBase>
                    )
                  )}
                </Fragment>
              ) : (
                <div className='text-gray-400 flex items-center mt-2'>
                  <i className='bi bi-exclamation-octagon text-lg mr-1 flex items-center' />{' '}
                  <span className='text-sm'>
                    {t('change.tab.client.noClientFound')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </TabPanel>
    </motion.div>
  );
};

export default TabOne;
