import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { LinearProgress, Pagination, TableHead } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import setTableCellType from 'utils/setTableCellType';
import TableColumnToggleDrawer from 'components/TableColumnToggleDrawer';
import useColumnToggle from 'hooks/useColumnToggle';
import addActiveInTableCell from 'utils/addActiveInTableCell';
import CustomContractCellBankTable from './parts/CustomContractCellBankTable';
import DeleteActionButton from '../action-buttons/DeleteActionButton';
import RejectActionButton from '../action-buttons/RejectActionButton';
import ModalActionButton from '../action-buttons/ModalActionButton';
import DoneActionButton from '../action-buttons/DoneActionButton';
import CustomSumCellBankTable from './parts/CustomSumCellBankTable';

export default function PaymentBankTable({
  dataPath = '',
  tableName = '',
  emitTableColumns = {},
  emitRefetch = {},
  setOpenContractModal = () => {},

  actionDelete = {},
  actionReject = {},
  actionAccept = {},
  actionModal = {},
}) {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const headCells = [
    { code: 'doc_date', label: t('common.table.date') },
    { code: 'sum', label: t('payment.add.contract.sum') },
    { code: 'nazpla', label: t('common.fields.comment') },
    { code: 'paymentContract', label: t('common.table.paymentContract') },
    { code: 'contract', label: t('contract.view.title') },
    { code: 'account_cr', label: t('common.fields.accountNumber') },
    { code: 'sender', label: t('common.table.sender') },
  ];
  const columns = [
    { code: 'doc_date', type: 'dateTime' },
    { code: 'sum' },
    { code: 'nazpla' },
    { code: 'paymentContract' },
    { code: 'contract' },
    { code: 'account_cr' },
    { code: 'sender' },
  ];
  const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
    useColumnToggle(
      tableName,
      addActiveInTableCell(headCells),
      addActiveInTableCell(columns)
    );
  const [queryPath, setQueryPath] = React.useState('');

  const [hasError, setHasError] = React.useState(false);
  const {
    error,
    data: rows,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: dataPath + 'Key',
    queryFn: async function () {
      const response = await axiosPrivate.get(queryPath);
      return response?.data?.data;
    },
    onSettled: () => {
      emitRefetch.setRefetch(false);
    },
    enabled: false,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    (searchParams.get('page') && parseInt(searchParams.get('page'))) || 1
  );

  React.useEffect(() => {
    if (queryPath && queryPath.length > 0) {
      refetch();
    }
  }, [queryPath]);

  React.useEffect(() => {
    createQueryPath();
  }, [searchParams]);

  React.useEffect(() => {
    if (emitRefetch && emitRefetch.refetch) {
      refetch();
    }
  }, [emitRefetch.refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  const createQueryPath = () => {
    let newQueryPath = `/${dataPath}`;
    let localSearchParams = Object.fromEntries([...searchParams]);
    const search = Object.keys(localSearchParams)?.filter(
      (key) => key !== 'staff' && key !== 'block'
    );
    search.forEach((item, index) => {
      if (item !== 'staff' && item !== 'block')
        if (index === 0) {
          newQueryPath += `?${item}=${localSearchParams[item]}`;
        } else {
          newQueryPath += `&${item}=${localSearchParams[item]}`;
        }
    });
    setQueryPath(newQueryPath);
    if (!isNaN(localSearchParams.page)) {
      setPage(parseInt(localSearchParams.page));
    } else {
      setPage(1);
    }
  };

  return (
    <Box className='base-table w-full h-full flex flex-col'>
      <TableContainer className='flex-auto h-full'>
        <Table
          stickyHeader
          sx={{ minWidth: 750, height: 'max-content' }}
          aria-labelledby='tableTitle'
        >
          {tableHeadCells && tableColumnCells && (
            <React.Fragment>
              <TableHead>
                <TableRow>
                  {tableHeadCells?.map(({ label, code }) => (
                    <TableCell align={'left'} key={code}>
                      {label}
                    </TableCell>
                  ))}
                  <TableCell padding='normal' align='right'>
                    {t('common.table.actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading || isFetching ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isError ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <div className='flex flex-col items-center'>
                        {error?.response?.data?.message && (
                          <span className='text-red-600 font-medium'>
                            {error?.response?.data?.message}
                          </span>
                        )}
                        {error?.response?.data?.details &&
                          error?.response?.data?.details[0]?.message && (
                            <div>
                              <span className='text-red-600 font-medium'>
                                {t('common.errors.message')}
                              </span>
                              <span>
                                {error?.response?.data?.details[0]?.message}
                              </span>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rows && rows?.data && rows?.data?.length > 0 ? (
                <React.Fragment>
                  <TableBody className='overflow-hidden'>
                    {rows.data.map((row, rowIndex) => {
                      return (
                        <TableRow hover tabIndex={-1} key={'row-' + rowIndex}>
                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells[columnIndex]?.numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column.code == 'sum' ? (
                                <CustomSumCellBankTable data={row} />
                              ) : column.code == 'contract' ? (
                                <CustomContractCellBankTable
                                  data={row}
                                  setData={actionModal.setData}
                                  setOpen={setOpenContractModal}
                                />
                              ) : column?.type ? (
                                setTableCellType(column, row[column?.code], row)
                              ) : (
                                row[column?.code]
                              )}
                            </TableCell>
                          ))}
                          <TableCell align={'right'}>
                            <div className='flex gap-1 justify-end'>
                              <div>
                                <ModalActionButton
                                  icon={actionModal.icon}
                                  btnColor={actionModal.btnColor}
                                  setOpen={actionModal.setOpen}
                                  setData={actionModal.setData}
                                  data={row}
                                />
                              </div>
                              {row?.contract_id && row?.iscount == 0 ? (
                                <DoneActionButton
                                  link={`/${actionAccept.link}/${row.id}`}
                                  successMsg={actionAccept.successMsg}
                                  setRefetch={emitRefetch.setRefetch}
                                  hasTooltip={actionAccept.hasTooltip}
                                  tooltipProps={actionAccept.tooltipProps}
                                />
                              ) : null}
                              {row?.contract_id && row?.iscount == 1 ? (
                                <RejectActionButton
                                  link={`/${actionReject.link}/${row.id}`}
                                  successMsg={actionReject.successMsg}
                                  setRefetch={emitRefetch.setRefetch}
                                  hasTooltip={actionReject.hasTooltip}
                                  tooltipProps={actionReject.tooltipProps}
                                />
                              ) : null}
                              <div>
                                <DeleteActionButton
                                  link={actionDelete.link}
                                  data={{ [actionDelete.key]: row.id }}
                                  refetch={refetch}
                                  disabled={
                                    row?.contract_id && row?.iscount == 1
                                  }
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </React.Fragment>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <span className='no-data-found-wrapper'>
                        <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                        {t('common.global.noDataFound')}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
      {rows && rows?.data && rows?.data?.length > 0 && (
        <div className='p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2'>
          <Pagination
            count={Math.ceil(rows?.total / rows?.per_page) || 1}
            page={page}
            onChange={handleChangePage}
            variant='outlined'
            color='primary'
            showFirstButton
            showLastButton
          />
        </div>
      )}

      {emitTableColumns && Object.keys(emitTableColumns).length === 2 && (
        <TableColumnToggleDrawer
          open={emitTableColumns.open}
          setOpen={emitTableColumns.setOpen}
          tableData={tableData}
          setTableHeadCells={setTableHeadCells}
        />
      )}
    </Box>
  );
}
