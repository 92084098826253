import React, { memo, useState } from 'react';
import SuccessTooltip from 'components/ui/tooltips/SuccessTooltip';
import { Link } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NestedMenuItem } from 'mui-nested-menu';

const ContractDownloaderByLang = ({ contractQuery }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let anchorEl = document.getElementById('contract-download-by-lang-btn');

  const menuToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <SuccessTooltip
        arrow={true}
        placement='top'
        title={t('contract.view.action.download')}
      >
        <Button
          size='small'
          onClick={menuToggle}
          startIcon={<i className='bi bi-download !text-sm' />}
          endIcon={<i className='bi bi-chevron-down !text-[12px]' />}
          id='contract-download-by-lang-btn'
        >
          {t('common.button.download')}
        </Button>
      </SuccessTooltip>

      <Menu
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={menuToggle}
        className='language-menu -translate-x-2.5 -translate-y-2'
      >

        <NestedMenuItem label={t('contract.view.title')} parentMenuOpen={open}>

          <Link
            target='_blank'
            to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}?lang=uz`}
            className='no-underline'
          >
            <MenuItem
              lang='uz'
              className={`language-menu-item`}
            >
              {t('common.language.short.uz')}
            </MenuItem>
          </Link>
          <Link
            target='_blank'
            to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}?lang=uz_kr`}
            className='no-underline'
          >
            <MenuItem
              lang='uz_kr'
              className={`language-menu-item`}
            >
              {t('common.language.short.uz_kr')}
            </MenuItem>
          </Link>
          <Link
            target='_blank'
            to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}?lang=ru`}
            className='no-underline'
          >
            <MenuItem
              lang='ru'
              className={`language-menu-item`}
            >
              {t('common.language.short.ru')}
            </MenuItem>
          </Link>

        </NestedMenuItem>

        <Link
          target='_blank'
          to={`${process.env.REACT_APP_BACKEND_URL}/payment-table/${contractQuery?.data?.id}`}
          className='no-underline'
        >
          <MenuItem onClick={menuToggle}>
            {t('contract.view.paymentTable')}
          </MenuItem>
        </Link>
        <Link
          target='_blank'
          to={`${process.env.REACT_APP_BACKEND_URL}/payment-history/${contractQuery?.data?.id}`}
          className='no-underline'
        >
          <MenuItem onClick={menuToggle}>
            {t('contract.view.paymentHistory')}
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default memo(ContractDownloaderByLang);
